import React from 'react';
import ArticleComponent from '../components/Blog/articleComponent';
import ArticleHomeComponent from '../components/Blog/articleHomeComponent';
import Header from '../components/headerComponent';
import Layout from '../components/layout';
import { graphql } from 'gatsby';

const BlogAgreedSocializeIdeas = props => {
  const {
    data: {
      wpPost: {
        seo,
        blogArticleSection: {
          blogArticleAuthor,
          blogArticleData,
          blogArticleReadingTime,
          blogArticleText,
          blogArticleTitle,
          blogArticleImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogArticleImage },
            },
          },
          blogRecommendedArticleEmployeesImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: blogRecommendedArticleEmployeesImage,
              },
            },
          },
          blogRecommendedArticleStrategyImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: blogRecommendedArticleStrategyImage,
              },
            },
          },
          blogRecommendedArticleStudentsImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: blogRecommendedArticleStudentsImage,
              },
            },
          },
          blogArticleSectionTwoText,
          blogArticleSectionThreeText,
          blogArticleSecondImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogArticleSecondImage },
            },
          },
          blogArticleThirdImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogArticleThirdImage },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <Header />
      <ArticleHomeComponent
        articleHomeTitle={blogArticleTitle}
        articleHomeData={blogArticleData}
        articleHomeAuthor={blogArticleAuthor}
        articleHomeTimeForReading={blogArticleReadingTime}
        articleImage={blogArticleImage}
        blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
        blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
        blogArticleStopSharingIdeasImage={blogRecommendedArticleEmployeesImage}
      />
      <ArticleComponent
        articleText={blogArticleText}
        blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
        blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
        blogArticleStopSharingIdeasImage={blogRecommendedArticleEmployeesImage}
        articleDescription={blogArticleSectionTwoText}
        articleSubDescription={blogArticleSectionThreeText}
        articleSecondImage={blogArticleSecondImage}
        articleThirdImage={blogArticleThirdImage}
        tagsName="Idea Management"
        categoriesName="Transformation"
        blogArticleTitle="Why Employees Stop Sharing Ideas"
        blogArticleData="11 Feb 2022"
        blogArticleTime="5 min read"
        blogArticleTwoData="23 Mar 2022"
        blogArticleTwoTime="9 min read"
        blogArticleTwoTitle="From Strategy to Delivery - closing the gap"
        blogArticleThreeData="15 Sep 2022"
        blogArticleThreeTime="5 min read"
        blogArticleThreeTitle="Cultivating the start-up and innovation mindset in collage"
        firstArticleRecommendedLink="/blog/why-employees-stop-sharing-ideas"
        SecondArticleRecommendedLink="/blog/from-strategy-to-delivery-closing-the-gap"
        thirdArticleRecommendedLink="/blog/cultivating-the-start-up-and-innovation-mindset-in-college"
      />
    </Layout>
  );
};

export default BlogAgreedSocializeIdeas;
export const query = graphql`
  query {
    wpPost(slug: { eq: "build-consensus" }) {
      seo {
        metaDesc
        title
      }
      blogArticleSection {
        blogArticleAuthor
        blogArticleData
        blogArticleReadingTime
        blogArticleText
        blogArticleTitle
        blogArticleImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleEmployeesImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStrategyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStudentsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleSectionTwoText
        blogArticleSectionThreeText
        blogArticleSecondFore
        blogArticleSecondImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleThirdImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
